	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/04/2020 07:20
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 16px;
font-style: normal;
margin-left: 5px;
margin-right: 5px;
}

.flaticon-playground:before { content: "\f100"; }
.flaticon-trampoline:before { content: "\f101"; }
.flaticon-jacuzzi:before { content: "\f102"; }
.flaticon-hammock:before { content: "\f103"; }
.flaticon-golf:before { content: "\f104"; }
.flaticon-golfer:before { content: "\f105"; }
.flaticon-gym:before { content: "\f106"; }
.flaticon-fitness-gym:before { content: "\f106"; }
.flaticon-plant:before { content: "\f107"; }
.flaticon-bed:before { content: "\f108"; }
.flaticon-bike:before { content: "\f109"; }
.flaticon-house:before { content: "\f10a"; }
.flaticon-car:before { content: "\f10b"; }
.flaticon-health:before { content: "\f10c"; }
.flaticon-clapperboard:before { content: "\f10d"; }
.flaticon-boulevard:before { content: "\f10e"; }
.flaticon-sunset:before { content: "\f10f"; }
.flaticon-bbq:before { content: "\f110"; }
.flaticon-sportive:before { content: "\f111"; }
.flaticon-playground-1:before { content: "\f112"; }
.flaticon-badminton-court:before { content: "\f113"; }
.flaticon-greek:before { content: "\f114"; }
.flaticon-concierge:before { content: "\f115"; }
.flaticon-sheild:before { content: "\f115"; }
.flaticon-stand:before { content: "\f116"; }
.flaticon-woman:before { content: "\f117"; }
.flaticon-lake:before { content: "\f118"; }
.flaticon-marina-bay-sands:before { content: "\f119"; }
.flaticon-mosque:before { content: "\f11a"; }
.flaticon-hotel:before { content: "\f11b"; }
.flaticon-feeder:before { content: "\f11c"; }
.flaticon-gym-1:before { content: "\f11d"; }
.flaticon-jacuzzi-1:before { content: "\f11e"; }
.flaticon-parking:before { content: "\f11f"; }
.flaticon-swimming:before { content: "\f120"; }
.flaticon-child:before { content: "\f120"; }
.flaticon-pets:before { content: "\f121"; }
.flaticon-pavilion:before { content: "\f122"; }
.flaticon-park:before { content: "\f123"; }
.flaticon-park-1:before { content: "\f124"; }
.flaticon-medication:before { content: "\f125"; }
.flaticon-terrace:before { content: "\f126"; }
.flaticon-sauna:before { content: "\f127"; }
.flaticon-person-silhouette-in-sauna:before { content: "\f127"; }
.flaticon-food:before { content: "\f128"; }
.flaticon-mall:before { content: "\f129"; }
.flaticon-beach:before { content: "\f12a"; }
.flaticon-beach-1:before { content: "\f12a"; }
.flaticon-spa:before { content: "\f12b"; }
.flaticon-lounge-chair:before { content: "\f12c"; }
.flaticon-market:before { content: "\f12d"; }
.flaticon-pool:before { content: "\f12e"; }
.flaticon-table-tennis:before { content: "\f12f"; }
.flaticon-theater:before { content: "\f130"; }
.flaticon-volleyball-court:before { content: "\f131"; }
.flaticon-valet-parking:before { content: "\f132"; }
.flaticon-splash:before { content: "\f133"; }
.flaticon-ship:before { content: "\f134"; }
.flaticon-promenade:before { content: "\f135"; }
.flaticon-farming:before { content: "\f136"; }
.flaticon-train-station:before { content: "\f137"; }
.flaticon-billiard:before { content: "\f138"; }
.flaticon-yoga-mat:before { content: "\f139"; }
.flaticon-playground-2:before { content: "\f13a"; }
.flaticon-meeting:before { content: "\f13b"; }
.flaticon-landmark:before { content: "\f13c"; }
.flaticon-park-2:before { content: "\f13d"; }
.flaticon-double-king-size-bed:before { content: "\f13e"; }
.flaticon-roof:before { content: "\f13f"; }
.flaticon-gates:before { content: "\f140"; }
.flaticon-grass:before { content: "\f141"; }
.flaticon-room:before { content: "\f142"; }
.flaticon-school:before { content: "\f143"; }