@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/smui/kit/core/style'; // Clean UI KIT styles
@import 'components/smui/styles/style'; // Clean UI styles

@import 'components/smui/styles/font-flaticon/flaticon.css';

$margin: 20px;
//SM UI Primary Color #be9c4a
// Secondary #222222
[data-kit-theme='default'] {
  .ant-btn {
    line-height: 1;
  }
  .ant-table-pagination.ant-pagination {
    margin: $margin 0 0;
  }
  .ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
  }
  .ant-tabs-bar {
    margin: 0 0 $margin 0;
  }
  .ant-btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
  .ant-switch-checked {
    background-color: $primary;
  }
  .ant-switch-checked::after {
    left: calc(100% - 20px);
  }
  .ant-form-vertical .ant-form-item-label {
    margin: 0;
    padding: 0 0 2px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    padding: 0 0 8px;
  }
  .ant-checkbox-inner::after {
    top: 45%;
  }
  .ant-table-row-expand-icon-cell {
    button {
      float: none;
    }
  }
  .cui__utils__content {
    padding: 1.4rem 2rem 2rem 2rem;
  }
  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .show > .btn.btn-primary:hover,
  .show > .btn.btn-primary:active {
    background-color: $secondary;
    border-color: $secondary;
  }
  .breadcrumbs {
    padding: 0 0;
    font-size: rem(17);
    margin-bottom: 20px;
    a {
      color: $black;
    }
    .ant-breadcrumb-link {
      color: $black;
    }
  }
  .text-danger {
    .lnr-thumbs-up {
      transform: scaleY(-1);
      display: block;
    }
  }
  .dashboardMenu {
    .ant-menu-inline {
      .ant-menu-item {
        width: 160px;
        height: 165px;
        text-align: center;
        padding: 20px 15px !important;
        float: left;
        margin: 10px;
        border: 1px solid $primary;

        border-radius: 10px;
        .icons {
          font-size: 50px;
          width: 100px;
          height: 100px;
          margin: 0 auto;
          border-radius: 100%;
          color: $primary;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background-color: $primary;
          .label,
          .icons {
            color: #fff;
          }
        }
      }
    }
  }
  .chart {
    :global(.ct-series-a) {
      :global(.ct-area) {
        fill: rgba(75, 124, 243, 0.1);
        fill-opacity: 1;
      }

      :global(.ct-line) {
        stroke: $primary;
      }
    }
  }
  .card-header,
  .card-footer,
  .card-body {
    padding: 20px;
  }
  .ant-form {
    .form-actions {
      border-top: 0;
      padding-top: 0;
      margin: 0;
    }
  }
  .ant-timeline p {
    margin-bottom: 5px;
  }
  //Table List Universal Component
  .user-lts {
    .ant-table-thead > tr > th {
      padding: 12px 5px;
    }
    .ant-table-tbody > tr > td {
      padding: 5px;
    }
    .action-btn-pad {
      padding: 0;
      line-height: 28px;
      height: 26px;
      width: 26px;
      align-items: center;
      justify-content: center;
    }
  }
  .anticon-search {
    font-size: 16px;
    height: 16px;
  }

  .addnew_searchbar {
    margin-bottom: $margin;
    .form-row {
      &.form-search {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .search-input {
        flex: 0 0 260px;
        max-width: 260px;
        @media (max-width: $lg-max-width) {
          flex: 0 0 40%;
          max-width: 40%;
        }
        @media (max-width: $md-max-width) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 15px;
        }
        .ant-select,
        select {
          width: 100%;
        }
      }
      .search-button,
      .addnew-button {
        flex: 0 0 120px;
        max-width: 120px;
        @media (max-width: $md-max-width) {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 15px;
        }
        button {
          width: 100%;
        }
      }
      .addnew-button {
        @media (max-width: $md-max-width) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    &.developer-searchbar {
      .search-input {
        flex: 0 0 260px;
        max-width: 260px;
        @media (max-width: $lg-max-width) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 15px;
        }
        @media (max-width: $md-max-width) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 15px;
        }
        .ant-select,
        select {
          width: 100%;
        }
      }
    }
  }
  .bottomBtnbar {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    margin-top: $margin;
    @media (min-width: $lg-min-width) {
      position: absolute;
      bottom: 20px;
      width: auto;
      display: flex;
    }
  }
  // .style_logo__1Zuz2 {
  //   img {
  //     width: 105px;
  //   }
  // }

  .ant-btn-primary.select-delete,
  .delt-hover {
    &:hover,
    &:active,
    &:focus {
      background: $red !important;
      border-color: $red !important;
    }
  }

  .rc-color-picker-trigger {
    width: 140px;
    height: 50px;
  }
  /* User Profile */
  .profile-card {
    .ant-tabs-top-bar {
      margin: 0 0 16px 0;
    }
  }
  /* Developer Module/Components Styling */
  .DeveloperLogo,
  .bannerImage,
  .Image-upload {
    margin-bottom: 0;
    .ant-upload-list-picture-card-container,
    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      margin-bottom: -8px;
      height: 140px;
    }
  }

  .color-pallete {
    .rc-color-picker-trigger {
      width: 90px;
      height: 40px;
    }
  }

  .tag {
    .ant-tag {
      margin: 0 4px;
    }
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
    // max-width: 100%;
    @media (min-width: $xl-min-width) {
      max-width: 400px;
    }
  }

  /* Add/Edit Property page */
  .property-form {
    .bedrooms-checkbox-style,
    .pptType-checkbox-style {
      padding: 15px !important;
      background: $gray-1;
      border: 1px solid #e4e9f0;
      .ant-checkbox-group-item {
        @media (min-width: $xl-min-width) {
          width: 28%;
        }
      }
    }
    .DLD_project_id {
      label {
        white-space: nowrap;
        letter-spacing: -0.02em;
      }
    }
    .ant-upload-list-item-info {
      .ant-upload-list-item-name-icon-count-1 {
        padding-right: 48px;
      }
      .ant-upload-list-item-card-actions {
        right: 20px;
      }
    }
    .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon {
      top: 56%;
    }
    .post-handover {
      white-space: nowrap;
      margin-top: 30px;
    }
    .post-handover-months {
      margin-top: 25px;
      @media (min-width: $xl-min-width) {
        width: 100%;
      }
      width: 150px;
      input[type='number'] {
        text-align: center;
        background-color: $gray-1;
      }
    }
    .custom-repeater-fields {
      margin: -24px -24px 24px;
      padding-top: 15px;
      border-bottom: 1px solid #d9dde6;
      align-items: center;
      .DeveloperLogo {
        min-height: 60px;
        .ant-upload.ant-upload-select-picture-card {
          height: 60px;
        }
      }
    }
    .property-photo-repeater {
      margin: 5px;
      border: 1px solid #d9dde6;
    }
    .pptType-checkbox-style {
      .ant-checkbox-group {
        display: flex;
        white-space: nowrap;
        flex-wrap: wrap;
        .ant-checkbox-group-item {
          width: 46%;
        }
      }
    }
    .property-form-features {
      padding: 15px !important;
      border: 1px solid #e4e9f0;
      .ant-col-6 {
        flex: 0 0 100%;
        max-width: 100%;
        @media screen and (min-width: 768px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media screen and (min-width: 1200px) {
          flex: 0 0 32%;
          max-width: 32%;
        }
      }
      .seperator {
        height: rem(15);
        width: 4px;
        border-radius: 3px;
        flex-shrink: 0;
        background-color: #e4e9f0;
        display: inline-block;
        margin: 3px 3px -3px 0;
      }
      label[title='Features'] {
        font-weight: bold;
        margin-bottom: 8px;
      }
      [class^='flaticon-']:before,
      [class*=' flaticon-']:before {
        font-size: 18px;
        line-height: 30px;
        vertical-align: baseline;
      }
    }
    .property-form-offers {
      padding: 15px !important;
      border: 1px solid #e4e9f0;
      label[title='Offers'] {
        font-weight: bold;
        margin-bottom: 8px;
      }
      label.ant-checkbox-group-item.ant-checkbox-wrapper {
        width: 100%;
        margin-bottom: 5px;
        @media screen and (min-width: 768px) {
          width: 48%;
        }
        @media screen and (min-width: 1200px) {
          width: 32%;
        }
      }
    }
    .brochure-field-block {
      .ant-form-item-has-success {
        .brochure-upload1 {
          background-color: #fafafa;
        }
      }
    }
    .DeveloperLogo,
    .brochure-upload,
    .brochure-upload1 {
      .ant-upload-list-item-info > span {
        display: flex;
        width: 100%;
        height: 100%;
      }

      span[aria-label='upload'] {
        svg {
          width: 3em;
          height: 2em;
        }
      }
    }
    .brochure-field {
      margin-bottom: 0;
      .ant-form-item-control {
        height: 0;
      }
    }
    .brochure-upload1 {
      width: 100%;
      border: 1px dashed #e2e2e2;
      padding: 5px;
      text-align: center;
      display: flex;
      height: 140px;
      justify-content: center;
      align-items: center;
      margin: 0;
      background-color: #fafafa;
    }
    .brochure-upload {
      .ant-upload-select-picture,
      .ant-upload-list-item {
        width: 100%;
        border: 1px dashed #e2e2e2;
        padding: 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background-color: #fafafa;
        .ant-upload-list-item-info {
          padding: 0;
          height: auto;
          /* height: 40px; */
          position: relative;
          width: 100%;
          text-align: left;
          .ant-upload-list-item-name {
            margin-top: 8px;
          }
        }
      }
    }

    .save-property-btn {
      font-size: 18px;
      height: 40px;
      padding: 10px 40px;
    }
  }
  .ck-editor__editable_inline {
    min-height: 200px;
    a {
      color: $primary;
      &:hover {
        color: $secondary;
      }
    }
  }
  //Leads Module
  .LeadsModal {
    .ant-drawer-content-wrapper {
      width: 400px !important;
      @media (max-width: $sm-max-width) {
        width: 100% !important;
      }
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
    .btn {
      width: 100%;
    }
  }
  .counterCards {
    .card {
      @media (max-width: $lg-max-width) {
        margin-bottom: 15px;
      }
    }
  }
  .ant-form-item {
    @media (max-width: $lg-max-width) {
      margin-bottom: 15px;
    }
  }
  .leads-information {
    padding: 15px 15px 5px 15px;
    @media (max-width: $lg-max-width) {
      padding: 5px;
    }
    .ant-descriptions-view {
      background: #fff;
      th.ant-descriptions-item-label {
        width: 130px;
        padding: 5px;
        font-size: 14px;
        white-space: nowrap;
        @media (max-width: $lg-max-width) {
          width: 100px;
        }
      }
      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        padding: 5px 15px;
        white-space: normal;
        @media (max-width: $lg-max-width) {
          padding: 5px 5px;
        }
      }
    }
    @media (max-width: $lg-max-width) {
      tr.ant-descriptions-row {
        display: flex;
        vertical-align: top;
        flex-wrap: wrap;
        border-bottom: none;
        th.ant-descriptions-item-label {
          flex: 35%;
          max-width: 35%;
          border-bottom: 1px solid #e4e9f0;
        }

        td {
          flex: 65%;
          max-width: 65%;
          border-right: 0;
          border-bottom: 1px solid #e4e9f0;
        }
      }
    }
    .LeadButtonArea {
      margin-right: 0;
      button,
      .ant-popover-disabled-compatible-wrapper {
        max-width: 100%;
        margin-bottom: 10px;
        margin-left: 8px;
        padding: 5px 6px;
        border: 0;
        font-size: 13px;
        &.ant-popover-disabled-compatible-wrapper button {
          margin-bottom: 0;
        }
      }
      span.ant-popover-disabled-compatible-wrapper {
        padding: 0;
        button {
          margin-left: 0;
          color: $gray-4;
        }
      }
      .ant-btn-sm {
        font-size: 13px;
        button {
          height: 24px;
          padding: 0 7px;
          font-size: 14px;
          border-radius: 2px;
        }
      }
      button {
        color: $light;
        border-color: $primary;
        background-color: $primary;
        &.danger {
          background-color: $danger;
          border-color: $danger;
        }
      }
      .ant-btn[disabled] {
        background-color: $gray-1;
        border: 1px solid gainsboro;
      }
    }
  }
  .leads-table-headerButtons {
    flex-wrap: wrap;
    margin: 0;
    .col {
      @media (max-width: $sm-max-width) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      &:first-child {
        @media screen and (min-width: 992px) and (max-width: 1050px) {
          flex: 0 0 150px;
          max-width: 150px;
        }
        @media (max-width: $sm-max-width) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        @media (max-width: $lg-max-width) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      &:nth-child(2) {
        @media screen and (min-width: 992px) and (max-width: 1050px) {
          flex: 0 0 160px;
          max-width: 160px;
        }
        @media (max-width: $lg-max-width) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
    button,
    .ant-popover-disabled-compatible-wrapper {
      max-width: 100%;
      margin-bottom: 15px;
      margin-left: 8px;
      @media (max-width: $md-max-width) {
        width: 100%;
        margin-left: 0;
      }
      &.ant-popover-disabled-compatible-wrapper button {
        margin-bottom: 0;
      }
    }
    span.ant-popover-disabled-compatible-wrapper {
      padding: 0;
      button:disabled {
        margin-left: 0;
        color: $gray-4;
      }
    }
    // button,
    // .ant-popover-disabled-compatible-wrapper {
    //   width: 100%;
    //   margin-bottom: 16px;
    //   &.ant-popover-disabled-compatible-wrapper button {
    //     margin-bottom: 0;
    //   }
    // }
    button {
      color: $light;
      border-color: $primary;
      background-color: $primary;
      &.danger {
        background-color: $danger;
        border-color: $danger;
      }
    }
    .ant-btn[disabled] {
      background-color: $gray-1;
    }
  }

  .Leads-table {
    @media screen and (max-width: $md-max-width) {
      .ant-table-thead {
        display: none;
      }
      .ant-table-row {
        display: block;
      }
      .ant-table-row td {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #dcdfe3;
        div,
        label {
          flex: auto;
          max-width: initial;
          text-align: left;
          &.agent-avatar {
            width: 20px;
          }
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(1),
        &:nth-child(3) {
          width: 33.33%;
          float: left;
          &:before {
            display: none;
          }
        }
        &:nth-child(4) {
          width: 33.33%;
          float: left;
        }
        &:last-child {
          &:before {
            display: none;
          }
        }
      }
      .ant-table-row td:before {
        content: attr(data-title) ': ';
        flex: 80px 1;
        max-width: 80px;
        text-align: left;
      }
    }
    .ant-table-row-expand-icon-cell.ant-table-cell-fix-left {
      position: sticky !important;
      z-index: 20;
    }
  }

  .ant-descriptions-view {
    table {
      border-collapse: collapse;
    }
  }
  //Logo expand/Collapse logo adjustment
  .ant-layout-sider.ant-layout-sider-collapsed {
    [class^='style_logo__'],
    [class*=' style_logo__'] {
      overflow: hidden;
      transform: scale(1.5);
      img.logo {
        position: relative;
        right: 52px;
        transform: scale(0.48);
      }
    }
  }

  .DeveloperLogo {
    min-height: 140px;
    .ant-upload-list-picture-card-container {
      min-height: 140px;
      .ant-upload-list-item {
        min-height: 140px;
        .ant-upload-list-item-thumbnail img {
          min-height: 120px;
          object-fit: contain;
          max-height: 120px;
        }
      }
    }
  }
  //src/pages/leads/import-csv/import-form.js
  .importCSV {
    div {
      width: 100% !important;
      text-align: right;
    }
  }
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 0;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
