// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/components/smui/kit/core/mixins.scss'; // import KIT mixins
@import 'src/components/smui/styles/mixins.scss'; // import CLEANUI mixins
// $text: #000;
$success: green;
.test {
  margin: 0;
}
.bg-success {
  background-color: $success !important;
}
.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: $secondary;
}
